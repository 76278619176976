import { css } from "aphrodite";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { saveReplyPromise } from "routines/review";

import EditableText from "components/Common/FormElements/EditableTextAsync";
import EntityImage from "components/Entities/EntityImage";

// import EntityLink from 'components/Entities/EntityLink';
// import { entityNames } from 'constants/entity';
import ReviewCardContext from "./ReviewCardContext";

import { selectSpecificUser } from "selectors/user";
import getEntityName from "utils/entity/getEntityName";
import getUserDisplayName from "utils/entity/getUserDisplayName";

import useReduxState from "hooks/useReduxState";
import useReviewReply from "hooks/useReviewReply";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

// import { capitalize } from 'utils/misc';

const baseStyles = {
  reviewReplyInput: {
    margin: "1rem 0",
    fontSize: "1rem",
    borderRadius: 14,
    width: "100%",
    position: "relative",
  },
  error: {
    marginTop: "1rem",
    color: colours.negative,
    textAlign: "center",
  },
  inputProfileImageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  inputProfileImage: {
    width: "2rem",
    height: "2rem",
  },
  inputProfileBack: {
    cursor: "pointer",
    position: "absolute",
    top: ".5rem",
    left: "1rem",
    color: colours.oldSecondary,
    fontSize: "1.25rem",
    height: "2rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  inputProfileTitle: {
    ...gStyles.fontSemiBold,
    color: colours.black,
    fontSize: ".8125rem",
    marginLeft: ".5rem",
  },
  reviewReplyInputProfileSelect: {
    padding: "1rem",
  },
  profileSelectTitle: {
    ...gStyles.fontSemiBold,
    color: colours.black,
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: "2rem",
  },
  profilePanelsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  profilePanel: {
    display: "flex",
    flexDirection: "column",
    width: "12rem",
    maxWidth: "12rem",
    overflow: "hidden",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 8,
    backgroundColor: colours.white,
    padding: "1rem",
    cursor: "pointer",
    boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.04)",
    marginLeft: "1rem",

    ":hover": {
      boxShadow: "0 5px 16px 0 rgba(0, 0, 0, 0.07)",
    },
    ":first-child": {
      marginLeft: 0,
    },
  },
  profilePanelTitle: {
    ...gStyles.textEllipsis,
    ...gStyles.fontSemiBold,
    color: colours.black,
    fontSize: ".875rem",
    marginBottom: ".75rem",
  },
  profilePanelImage: {
    maxWidth: "3rem",
    marginBottom: "1rem",
  },
  profilePanelEntityType: {
    ...gStyles.fontMedium,
    color: colours.grey,
    fontSize: ".75rem",
  },
};

const baseEditableTextStyles = {
  input: {
    padding: "1rem 0",
  },
};

const ReviewReplyInput = (props) => {
  const {
    editableTextStyles: passedEditableTextStyles,
    review: passedReview,
    user: passedUser,
    onClose: passedOnClose,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const {
    onHideInput: pulledOnClose,
    review: pulledReview,
    user: pulledUser,
  } = useContext(ReviewCardContext) || {};

  const reduxUser = useReduxState(
    (state) =>
      selectSpecificUser(
        state,
        passedReview &&
          !pulledUser &&
          !passedUser &&
          passedReview.get("user_id")
      ),
    [passedReview, pulledUser]
  );

  const onClose = pulledOnClose || passedOnClose;
  const review = pulledReview || passedReview;
  const reviewUser = pulledUser || passedUser || reduxUser;

  const { possibleProfiles } = useReviewReply(review);

  const editableTextStyles = useMemo(() => {
    if (passedEditableTextStyles) {
      return deepmerge(baseEditableTextStyles, passedEditableTextStyles);
    }

    return baseEditableTextStyles;
  }, [passedEditableTextStyles]);

  const [selectedProfile, setSelectedProfile] = useState(null);

  const { saveReply } = useRoutinePromises({ saveReply: saveReplyPromise });

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (possibleProfiles.length === 1) {
      setSelectedProfile(possibleProfiles[0]);
    }
  }, [possibleProfiles]);

  const needsToSelectProfile = useMemo(
    () => !selectedProfile, // && possibleProfiles.length > 1;
    [selectedProfile]
  );

  const handleSubmit = useCallback(
    (content) => {
      setSaving(true);

      saveReply({
        entity_type: "review",
        entity_id: review.get("id"),
        reply_as_entity_type: selectedProfile && selectedProfile.entity_type,
        reply_as_entity_id: selectedProfile && selectedProfile.entity_id,
        content,
      })
        .then(onClose)
        .catch((e) => {
          setError(e.message);
        })
        .finally(() => setSaving(false));
    },
    [review, saveReply, onClose, selectedProfile]
  );

  const handleChange = useCallback(() => {
    if (error) {
      setError(null);
    }
  }, [error]);
  /*
  const handleProfileSelect = useCallback(profile => () => setSelectedProfile(profile), []);

  const renderProfilePanel = (profile) => {
    if (profile) {
      return (
        <div
          className={css(styles.profilePanel)}
          onClick={handleProfileSelect(profile)}
        >
          <div className={css(styles.profilePanelImage)}>
            <EntityImage
              entity_type={profile.entity_type}
              entity={profile.entity}
              disableLink
            />
          </div>
          <div className={css(styles.profilePanelTitle)}>
            <EntityLink
              entity_type={profile.entity_type}
              entity={profile.entity}
              disableLink
            />
          </div>
          <div className={css(styles.profilePanelEntityType)}>
            {capitalize(entityNames[profile.entity_type])}
          </div>
        </div>
      );
    }

    return null;
  };
  */

  if (needsToSelectProfile) {
    return null; // TODO: Enable this
    /*
    return (
      <div className={css(styles.reviewReplyInput, styles.reviewReplyInputProfileSelect)}>
        <h3 className={css(styles.profileSelectTitle)}>
          Which profile would you like to reply from?
        </h3>
        <div className={css(styles.profilePanelsContainer)}>
          {possibleProfiles.map(renderProfilePanel)}
        </div>
      </div>
    );
    */
  }

  return (
    <div className={css(styles.reviewReplyInput)}>
      <div className={css(styles.inputProfileImageContainer)}>
        <div className={css(styles.inputProfileImage)}>
          <EntityImage
            entity_type={selectedProfile.entity_type}
            entity={selectedProfile.entity}
            disableLink
          />
        </div>
        <div className={css(styles.inputProfileTitle)}>
          {`Replying as ${getEntityName(
            selectedProfile.entity,
            selectedProfile.entity_type
          )}`}
        </div>
      </div>
      <EditableText
        onCancel={onClose}
        onAccept={handleSubmit}
        onChange={handleChange}
        saving={saving}
        placeholder={`Respond to ${getUserDisplayName(reviewUser)}'s review...`}
        editing
        big
        textarea
        styles={editableTextStyles}
        // value={review.getIn('content')}
      />
      {error && <div className={css(styles.error)}>{error}</div>}
    </div>
  );
};

ReviewReplyInput.propTypes = {
  editableTextStyles: PropTypes.object,
  review: PropTypes.instanceOf(Map),
  user: PropTypes.instanceOf(Map),
  onClose: PropTypes.func,
};

ReviewReplyInput.defaultProps = {
  editableTextStyles: {},
  review: null,
  user: null,
  onClose: null,
};

export default memo(ReviewReplyInput);
